import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const uid = localStorage.getItem('token_web');

    if (uid) {
      console.log('Usuario autenticado');

      const data = this.authService.getUserPermissions();
      console.log('Permisos del Usuario:', data?.['permissions']);

      const userPermissions = data?.['permissions'] || [];
      console.log('User Permissions:', userPermissions);

      // Obtén los permisos de la ruta principal
      const mainRoutePermissions = route.data?.['permissions'] as string[] || [];
      console.log('Permisos de la Ruta Principal:', mainRoutePermissions);

      // Verifica si hay una ruta hija activa
      const activeChild = route.children.find(child => child.outlet === 'primary' && child.routeConfig);
      if (activeChild) {
        // Si hay una ruta hija activa
        const activeChildPermissions = activeChild.routeConfig?.data?.['permissions'] as string[] || [];
        console.log('Permisos de la Ruta Hija Activa:', activeChildPermissions);

        // Verifica si el usuario tiene al menos uno de los permisos de la ruta activa
        const hasRequiredPermissions = this.checkUserPermissions(userPermissions, activeChildPermissions);

        if (!hasRequiredPermissions) {
          console.log("Acceso denegado. Permisos insuficientes.");
          this.router.navigate(['/access-denied']);
          return false;
        }
      } else {
        // Si no hay una ruta hija activa (estás en la ruta principal), verifica si el usuario tiene al menos uno de los permisos principales
        const hasRequiredPermissions = this.checkUserPermissions(userPermissions, mainRoutePermissions);

        if (!hasRequiredPermissions) {
          console.log("Acceso denegado. Permisos insuficientes.");
          this.router.navigate(['/access-denied']);
          return false;
        }
      }

      return true;
    } else {
      console.log('Usuario no autenticado. Redirigiendo a la página de inicio de sesión.');
      this.router.navigateByUrl('auth/login');
      return false;
    }
  }

  private checkUserPermissions(userPermissions: string[], requiredPermissions: string[]): boolean {
    return requiredPermissions.length === 0 || userPermissions.some(permission => requiredPermissions.includes(permission));
  }
}
