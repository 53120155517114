export const environment = {
    production: false,
    //urlApi: 'http://localhost:4400/api/sistema_administrativo_gestocar/v1/',
    urlApi: 'https://api.gestocar.mx/api/sistema_administrativo_gestocar/v1/',
    apiKey: 'gestocar_apikey3jnyRMTWKaAxZI0aekphAqztWd29J7yCPA1hRZABCLP74MlcV',
    cryptoKey: 'gestocar_cryptokeyhTI4FSeH8dHyiQa5jhszoentc14cSN7viM4TbfdOYyBTeL',
    firebase: {
        apiKey: "AIzaSyCW21vA_WKlPbV9eYeBohOqTqk-my89Gjw",
        authDomain: "sistema-administrativo-c2cf6.firebaseapp.com",
        projectId: "sistema-administrativo-c2cf6",
        storageBucket: "sistema-administrativo-c2cf6.appspot.com",
        messagingSenderId: "572217707086",
        appId: "1:572217707086:web:164a3fa95a3a05ca23111f",
        measurementId: "G-9K6C44RCZB"
    }
}