import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/enviroments/enviroment';
import { CryptoService } from './utils/crypto.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url: string = environment.urlApi;
  private loginSubject = new Subject<any>();


  constructor(
    private http: HttpClient,
    private crypto: CryptoService
  ) { }

  login(payload: any){
    const scope = `${this.url}auth/login`;
    return this.http.post(`${scope}`, payload)
  }


  get_user_data() {
    const uid = localStorage.getItem('token_web');
    if(uid) {
      const token = this.crypto.decodeToken(uid);
      const data = this.crypto.decrypt(token.data);
      return JSON.parse(data);
    } else {
      return "no va";
    }
  }

  getUserPermissions(){
    const uid = localStorage.getItem('token_web');
    if(uid) {
      const token = this.crypto.decodeToken(uid);
      const permissions = this.crypto.decrypt(token.data);
      return JSON.parse(permissions);
    } else {
      return "no va";
    }
  }

  getLogin() {
    return this.loginSubject.asObservable();
  }

}
