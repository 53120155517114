import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/enviroments/enviroment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token_web = localStorage.getItem('token_web');
    const api_key: string = environment.apiKey;
    if (request.url === 'https://servicios2.gestocar.mx/wp-json/wp/v2/posts') {
      return next.handle(request);
    }    
    const headers = new HttpHeaders({
      'x-api-key': api_key,
      'Authorization': `Bearer ${token_web}`
    });

    const reqClone = request.clone({
      headers
    });

    return next.handle(reqClone);
  }
}
