import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from 'src/enviroments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() { }

  private ck: string = environment.cryptoKey;

  encrypt(msg: string): string {
    return AES.encrypt(msg, this.ck).toString();
  }

  decrypt(code: string): string {
    let bytes = AES.decrypt(code, this.ck);
    return bytes.toString(enc.Utf8);
  }

  decodeToken(token: string) {
    const helper = new JwtHelperService();
    return helper.decodeToken(token);
  }

  verifyCertKey() {
  }
}
